// const BASE_URL = "http://192.168.21.2:5000/node_api";
// const DEFAULT_KEY = '4ceb0a60-017b-4beb-8171-a36e4e1beabc';
// const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";

//demo
// const BASE_URL = "http://www.barber.user.iossmlm.com/node_api";
// const DEFAULT_KEY = '4ceb0a60-017b-4beb-8171-a36e4e1beabc';
// const PAYPAL_CLIENT_ID = "";

//live
const BASE_URL = "http://admin.tebtdclub.com/node_api";
const DEFAULT_KEY = '4ceb0a60-017b-4beb-8171-a36e4e1beabc';
const PAYPAL_CLIENT_ID = "";

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID } 